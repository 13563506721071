import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Layout from "../components/layout"
import Team from "../components/team"

const TeamTemplate = ({ data: { team } }) => {
  return (
    <Layout>
      <Seo post={team} />
      <Team data={team} />
    </Layout>
  )
}

export default TeamTemplate

export const pageQuery = graphql`
  query TeamById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current page by id
    team: wpTeam(id: { eq: $id }) {
      id
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfTeam {
        button {
          submitProjectGfFormId
          title
        }
        position
        services
      }
      content
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      title
      slug
    }
  }
`
